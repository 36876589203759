body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.game-form {
  position: absolute;
  width: 300;
  left: 20%;
  margin: 0 0 0 -200px;
  
  color: rgba(0, 0, 0, 0.5);
  background: transparent;
    border: none;
}

.transparent-input
{
    background: rgba(0, 0, 0, 0.15);
    border: none;
    margin-right: 30px;
    margin-left: 5px;
    width: 270px;
}

.score {
  position: absolute;
  font-size: 30px;
  right: 0%;
  width: 30%;
  margin: 0 0 0 -200px;
  
  color: rgba(0, 0, 0, 1);
  background: transparent;
  border: none;
}
.scorePercent {
  width: 140px;
} 

table {
  table-layout: fixed;
  width: 100%;
}